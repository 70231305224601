import { type NuxtApp, callWithNuxt } from "#app";
import { SHA3 } from "sha3";
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth/auth.store";
import { useRequestsStore } from "~~/store/requests/requests.store";
import { useSessionsStore } from "~~/store/sessions/sessions-store";
import { CONNEXION_STEPS } from "~/utils/enums/auth.enums";
import type { IUser } from "~~/utils/interfaces/auth-interfaces";

export const useAuth = () => {
  function hashPassword(password: string) {
    const hash = new SHA3(512);
    hash.update(password);
    return hash.digest("hex");
  }

  // To delete after few days
  /*   function handleRoleRedirect() {
    const authStore = useAuthStore();

    if (authStore.isManager || authStore.isCompanyAdmin || authStore.isGroupViewer || authStore.isGroupAdmin)
      return navigateTo("/requests");
    else if (authStore.isTrainingPlanAdmin) return navigateTo("/my-sessions");
    else return navigateTo("/catalog");
    // else
    //   return navigateTo(config.public.BUBBLE_APP_URL + 'catalog', { external: true });
  } */

  const router = useRouter();
  function isValidUrl(url: string): boolean {
    const cleanUrl = url.split("?")[0];
    const firstSegment = cleanUrl.split("/").filter(Boolean)[0];

    // Check if the route exists and if the URL is not empty
    if (!firstSegment) {
      return false;
    }

    return router.getRoutes().some((route) => route.path.startsWith(`/${firstSegment}`));
  }

  async function handleExternalRedirect(next_url?: string) {
    const app = useNuxtApp();

    const urlSSO = useCookie("sso-redirect").value || next_url;
    const url = useCookie("redirect_url").value || next_url;

    if (url && !urlSSO) {
      const urlRef = isValidUrl(url);

      if (urlRef) {
        return navigateTo(url, { replace: true });
      } else {
        return navigateTo("/home", { replace: true });
      }
    } else if (!urlSSO || !url) {
      // no specific cookie redirection found, use usual redirect
      return handleRedirect();
    }

    // delete redirect cookie
    useCookie("sso-redirect").value = null;

    if (!urlSSO.startsWith("training-plan/")) {
      return navigateTo("/" + urlSSO, { replace: true });
    }

    const authStore = useAuthStore();

    // await callWithNuxt(app, authStore.fetchProfile);
    await authStore.fetchProfile();

    const user = authStore.user;

    const segments = urlSSO.split("/");
    const last = segments.pop();

    const isAllowed = authStore.isManager || authStore.isCompanyAdmin || authStore.isGroupAdmin;

    // "tr-" indicator at beginning of id indicates where to redirect
    // "tr-" existing -> training plan/booking
    // "tr-" non-existing -> request
    if (last?.startsWith("tr-")) {
      return redirectTrainingPlan(app, user!, last.replace("tr-", ""), isAllowed);
    } else {
      return redirectRequest(app, user!, last!, isAllowed);
    }
  }

  async function redirectTrainingPlan(app: NuxtApp, user: IUser, id: string, isAllowed: boolean) {
    const sessionStore = useSessionsStore();

    await callWithNuxt(app, sessionStore.fetchTrainingPlanSession, ["tr-" + id]);
    const tp = sessionStore.training_plan_session_individual;

    if (tp?.user_id && user && tp?.user_id === user.user_id) {
      // training_plan.user_id === connected_user => redirect my-trainings/:id
      return callWithNuxt(app, navigateTo, [`/my-trainings?id=${id}&booking=true`, { replace: true }]);
    } else if (isAllowed) {
      // training_plan.user_id !== connected_user => redirect training-plan?id=:id&type=session
      return callWithNuxt(app, navigateTo, [
        `/training-plan?details_id=tr-${id}&type=session`,
        {
          replace: true,
        },
      ]);
    } else {
      return callWithNuxt(app, navigateTo, [`/catalog`, { replace: true }]);
    }
  }

  async function redirectRequest(app: NuxtApp, user: IUser, id: string, isAllowed: boolean) {
    const requestsStore = useRequestsStore();

    await callWithNuxt(app, requestsStore.fetchRequestDetails, [id]);
    const rd = requestsStore.request_details;
    if (rd?.user_id && user && rd?.user_id.user_id === user.user_id) {
      // requests.user_id === connected_user => redirect my-trainings/:id
      return callWithNuxt(app, navigateTo, [`/my-trainings?id=${id}&booking=false`, { replace: true }]);
    } else if (isAllowed) {
      // requests.user_id !== connected_user => redirect requests/:id
      return callWithNuxt(app, navigateTo, [`/requests/${id}`, { replace: true }]);
    } else {
      return callWithNuxt(app, navigateTo, [`/catalog`, { replace: true }]);
    }
  }

  function handleRedirect(): any {
    const authStore = useAuthStore();
    const { connexion } = storeToRefs(authStore);

    const url = useCookie("sso-redirect").value as string;

    if (authStore.isLoggedIn()) {
      if (!authStore.user?.firstname || !authStore.user?.lastname) {
        connexion.value.connexion_step = CONNEXION_STEPS.REGISTER_NAME;
        return navigateTo("/");
      } else if (url) {
        return handleExternalRedirect(url);
      } else {
        return navigateTo("/home");
      }
    } else {
      return navigateTo("/");
    }
  }

  return {
    hashPassword,
    handleRedirect,
    handleExternalRedirect,
  };
};
